export class SearchPanel {
  constructor(menuId, items) {
    this.navItems = items;
    this.menuId = menuId;
    this.currentItem = this.getfirstFocusableElement();
  }

  nextItem() {
    this.currentItem++;
    return this.navItems[(this.currentItem %= this.navItems.length)];
  }

  prevItem() {
    return this.navItems[(this.currentItem = --this.currentItem == -1 ? this.navItems.length - 1 : this.currentItem)];
  }

  getCurrentItem() {
    return this.navItems[this.currentItem];
  }

  resetMenu() {
    this.currentItem = 0;
  }

  getfirstFocusableElement() {
    return this.navItems.findIndex((item) => item.tagName === 'INPUT') ?? 0;
  }
}
export class Menu {
  constructor(menuElement, menuType, items = [], firstFocusableElement) {
    this.menuId = menuElement.id;
    this.menuType = menuType;
    this.navItems = items;
    this.firstFocusableElement = firstFocusableElement;
    this.currentItem = firstFocusableElement ?? 0;
    this.menuElement = menuElement;
  }

  nextItem() {
    this.currentItem++;
    return this.navItems[(this.currentItem %= this.navItems.length)];
  }

  prevItem() {
    return this.navItems[(this.currentItem = --this.currentItem == -1 ? this.navItems.length - 1 : this.currentItem)];
  }

  getCurrentItem() {
    return this.navItems[this.currentItem];
  }

  toggleSubMenu() {
    return (this.isSubMenu = !this.isSubMenu);
  }

  getBackButtonItem() {
    return this.navItems.find((item) => item.element.classList.contains('menu-back-btn'));
  }

  resetMenu() {
    this.currentItem = this.firstFocusableElement ?? 0;
  }
}
export class MenuItem {
  constructor(element, nestedMenu = null) {
    this.id = element.id;
    this.text = element.textContent.trim();
    this.element = element;
    this.nestedMenu = nestedMenu;
    this.controls = element.getAttribute('aria-controls');
  }
}
