if (document.readyState == 'complete') {
  initilizePurchaseOptionsTabs();
} else {
  window.addEventListener('load', function () {
    initilizePurchaseOptionsTabs();
  });
}

function debounce(func, delay) {
  let timeoutId;
  return function () {
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

function scrollListener(tabContainer, scrollLeft, maxScroll, btnLeft, btnRight) {
  if (btnRight) {
    if (scrollLeft >= maxScroll * 0.95) {
      btnRight.classList.add('d-none');
      tabContainer.style.paddingRight = 0;
    } else {
      btnRight.classList.remove('d-none');
    }
  }
  if (btnLeft) {
    if (scrollLeft > 0) {
      btnLeft.classList.remove('d-none');
    } else {
      btnLeft.classList.add('d-none');
      tabContainer.style.paddingLeft = 0;
    }
  }
}

function initilizePurchaseOptionsTabs() {
  const tabsContainers = document.querySelectorAll('.tabs-container');
  tabsContainers.forEach((tabContainer) => {
    const tabListWrapper = tabContainer.querySelector('.nav-tabs-wrapper');
    const tabList = tabContainer.querySelector('ul.nav-tabs');
    const btnLeft = tabContainer.querySelector('.left-scroll-btn');
    const btnRight = tabContainer.querySelector('.right-scroll-btn');
    const scrollStep = tabListWrapper.scrollWidth / 1.5;
    let maxScroll = tabListWrapper.scrollWidth - tabListWrapper.offsetWidth;
    if (maxScroll == 0) {
      setTimeout(function () {
        maxScroll = tabListWrapper.scrollWidth - tabListWrapper.offsetWidth;
        showRightArrow(tabContainer, tabListWrapper.scrollLeft, maxScroll, btnRight);
      }, 1000);
    }
    // This block handles tab behaviors to match a11y requirements
    // TODO: Remove this when update to Bootstrap 5.2.3
    const buttonTabList = document.querySelector('ul[role="tablist"]');
    if (buttonTabList) {
      const allButtons = tabList.querySelectorAll('button.nav-link');
      handleTabNavigation(allButtons);
    }
    scrollToActiveTab(tabListWrapper);
    showRightArrow(tabContainer, tabListWrapper.scrollLeft, maxScroll, btnRight);
    debounce(scrollListener(tabContainer, tabListWrapper.scrollLeft, maxScroll, btnLeft, btnRight), 0);
    tabListWrapper.addEventListener('scroll', () => {
      debounce(scrollListener(tabContainer, tabListWrapper.scrollLeft, maxScroll, btnLeft, btnRight), 0);
    });
    if (btnLeft) {
      btnLeft.addEventListener('click', () => {
        tabListWrapper.scrollTo({ left: tabListWrapper.scrollLeft - scrollStep, behavior: 'smooth' });
      });
    }
    if (btnRight) {
      btnRight.addEventListener('click', () => {
        tabListWrapper.scrollTo({ left: tabListWrapper.scrollLeft + scrollStep, behavior: 'smooth' });
      });
    }

    tabList.querySelectorAll('.nav-item .badge').forEach((badgeEl) => {
      badgeEl.style.width = `${badgeEl.nextElementSibling.clientWidth}px`;
    });
  });
}

// Function that will handle tab navigation
// TODO: Remove this when update to Bootstrap 5.2.3
function handleTabNavigation(buttonList) {
  const buttons = Array.from(buttonList);
  let selectedButton = buttons.find((button) => button.classList.contains('active'));
  // Function to update which button is selected
  function updateSelectedButton(newSelectedButton) {
    buttons.forEach((button) => {
      if (button === newSelectedButton) {
        button.classList.add('active');
        button.setAttribute('aria-selected', 'true');
        button.tabIndex = 0;
      } else {
        button.classList.remove('active');
        button.setAttribute('aria-selected', 'false');
        button.tabIndex = -1;
      }
    });
    selectedButton = newSelectedButton;
  }
  updateSelectedButton(selectedButton);
  // Add click listeners to update selected button
  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      updateSelectedButton(button);
    });
  });
}

function scrollToActiveTab(tabList) {
  const navLink = tabList.querySelector('.nav-item:has(.nav-link.active)');
  tabList.scrollTo({ left: navLink.offsetLeft, behavior: 'smooth' });
}

function showRightArrow(tabContainer, scrollLeft, maxScroll, btnRight) {
  if (scrollLeft < maxScroll * 0.95) {
    btnRight.classList.remove('d-none');
  }
}
