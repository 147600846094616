import { SearchPanel } from './globalNav/globalNavClasses';
import { MainNav } from './globalNav/a11yFunctions';

// Initialize the GlobalNav class
const globalNavElement = document.getElementById('global-nav');
if (globalNavElement) {
  new GlobalNav(globalNavElement);
}
class GlobalNav {
  constructor(globalNavElement) {
    this.globalNavElement = globalNavElement;
    this.mainNavHeightXl = 64;
    this.mainNavHeight = 60;
    this.ARIA_LABEL_CLOSE_MENU = 'Closes site menu options.';
    this.ARIA_LABEL_OPEN_MENU = 'Opens site menu options.';
    this.ARIA_LABEL_CLOSE_SEARCH = 'Close search panel.';
    this.ARIA_LABEL_OPEN_SEARCH = 'Opens search panel.';

    this.mainNavOffCanvas = globalNavElement.querySelector('#main-nav');
    this.menuButton = globalNavElement.querySelector('.navbar-toggle');
    this.searchMenuButton = globalNavElement.querySelector('.search-offcanvas-button');
    this.backdrop = globalNavElement.querySelector('.menu-page-backdrop');
    this.dropdownBtns = globalNavElement.querySelectorAll('#main-menu > li > button');
    this.firstItemInOtherNav = globalNavElement.querySelector('#other-nav a');

    this.searchOffcanvas = globalNavElement.querySelector('#search-offcanvas');

    this.searchOffcanvasCloseBtn = this.searchOffcanvas.querySelector('button.search-close-btn');
    this.searchLearningLevelDropdownButton = this.searchOffcanvas.querySelector('.searchLearningLevelDropdown button');
    this.searchLearningLevelDropdownButtonText = this.searchLearningLevelDropdownButton.querySelector('span');
    this.searchInput = this.searchOffcanvas.querySelector('input');
    this.searchButton = this.searchOffcanvas.querySelector('.search-btn');
    this.searchLearningLevelDropdownChoices = this.searchOffcanvas.querySelectorAll(
      '.searchLearningLevelDropdownItems .dropdown-item'
    );

    // eslint-disable-next-line no-undef
    this.searchOffcanvasBs = bootstrap.Offcanvas.getInstance(this.searchOffcanvas);
    // eslint-disable-next-line no-undef
    this.mainMenuOffcanvasBs = bootstrap.Offcanvas.getInstance(this.mainNavOffCanvas);

    this.megaMenu = new MainNav(this.mainNavOffCanvas);
    this.level2Menus = Object.values(this.megaMenu.menus).filter((menu) => menu.menuType === 'level2Menu');
    this.level3Menus = Object.values(this.megaMenu.menus).filter((menu) => menu.menuType === 'level3Menu');
    this.initializeEventListeners();
    this.setupSearchA11y();
  }

  initializeEventListeners() {
    this.mainNavOffCanvas.addEventListener('show.bs.offcanvas', this.handleMainNavShow.bind(this));
    this.mainNavOffCanvas.addEventListener('shown.bs.offcanvas', () => {
      this.mainNavOffCanvas.querySelector('.menu-close-btn').focus();
    });
    this.mainNavOffCanvas.addEventListener('hide.bs.offcanvas', this.handleMainNavHide.bind(this));
    this.searchOffcanvas.addEventListener('show.bs.offcanvas', this.handleSearchOffcanvasShow.bind(this));
    this.searchOffcanvas.addEventListener('shown.bs.offcanvas', () => {
      this.searchPanelNav.getCurrentItem().focus();
    });
    this.searchOffcanvas.addEventListener('hide.bs.offcanvas', this.handleSearchOffcanvasHide.bind(this));
    this.backdrop.addEventListener('click', this.handleBackdropClick.bind(this));

    this.setupSearchClickListenersAndA11y();
    this.setupMenuClickListenersAndA11y();
  }

  setupMenuClickListenersAndA11y() {
    this.menuButton.addEventListener('keydown', (e) => {
      if (e.code !== 'Tab') return;
      if (e.shiftKey) return;
      this.firstItemInOtherNav.focus();
      e.stopPropagation();
      e.preventDefault();
    });

    this.firstItemInOtherNav.addEventListener('keydown', (e) => {
      if (e.code !== 'Tab') return;
      if (e.shiftKey && this.menuButton.offsetParent !== null) {
        this.menuButton.focus();
        e.stopPropagation();
        e.preventDefault();
      }
    });

    Object.keys(this.megaMenu.menus).forEach((menu) => {
      const menuObj = this.megaMenu.menus[menu];
      switch (menuObj.menuType) {
        case 'main-menu':
          menuObj.navItems.forEach((item) => {
            if (item.element.tagName === 'BUTTON') {
              item.element.classList.contains('menu-close-btn')
                ? item.element.addEventListener('click', this.handleCloseBtnClick.bind(this))
                : item.element.addEventListener('click', () => this.handleDropDownButtonClick(item.element));
            }
          });
          menuObj.menuElement.addEventListener('keydown', (e) => this.navKeyboardA11y(e));
          break;
        case 'level2Menu':
          menuObj.navItems.forEach((item) => {
            if (item.element.tagName === 'BUTTON') {
              item.element.classList.contains('menu-close-btn')
                ? item.element.addEventListener('click', () => this.handleLevel2CloseBtnClick(menuObj))
                : item.element.classList.contains('menu-back-btn')
                ? item.element.addEventListener('click', () => this.handleBackButtonClick(menuObj))
                : item.element.addEventListener(
                    'click',
                    async () =>
                      await this.handleLevel2MenuButtonClick(
                        item.element,
                        this.megaMenu.menus[item.controls].menuElement,
                        menuObj
                      )
                  );
            }
          });
          menuObj.menuElement.addEventListener('keydown', (e) => this.level2KeyboardA11y(e, menuObj));
          break;

        case 'level3Menu':
          this.setupMenuFilter(menuObj.menuElement);
          menuObj.navItems.forEach((item) => {
            if (item.element.tagName === 'BUTTON') {
              if (item.element.classList.contains('menu-close-btn')) {
                item.element.addEventListener('click', () => this.handleLevel3CloseBtnClick(menuObj));
              } else if (item.element.classList.contains('menu-back-btn')) {
                item.element.addEventListener('click', () => this.handleBackButtonClick(menuObj));
              }
            }
          });
          menuObj.menuElement.addEventListener('keydown', (e) => this.level3KeyboardA11y(e, menuObj));
          break;
      }
    });
  }

  setupSearchClickListenersAndA11y() {
    this.searchOffcanvasCloseBtn.addEventListener('click', () => {
      this.searchOffcanvasBs.hide();
    });
    this.searchLearningLevelDropdownChoices.forEach((choice) => {
      choice.addEventListener('click', () => {
        this.searchLearningLevelDropdownButtonText.textContent = choice.textContent;
        this.searchLearningLevelDropdownButton.focus();
      });
    });

    this.searchOffcanvas.addEventListener('keydown', (e) => this.searchKeyboardA11y(e));
  }

  handleCloseBtnClick() {
    if (this.mainMenuOffcanvasBs) this.mainMenuOffcanvasBs.hide();
    Object.keys(this.megaMenu.menus).forEach((menuId) => {
      this.megaMenu.menus[menuId].resetMenu();
    });
  }

  handleLevel2CloseBtnClick(menu) {
    this.megaMenu.menus['main-nav'].navItems.forEach((item) => {
      if (item.controls === menu.menuId) {
        item.element.focus();
        return;
      }
    });
    this.hideSubMenus();
    this.removeActiveClasses();
    menu.navItems.forEach((item) => item.element.classList.remove('active'));
    this.toggleMenuBackdrop();
    this.sendFilterInfo();
    this.handleCloseBtnClick();
  }

  handleLevel3CloseBtnClick(menu) {
    this.megaMenu.menus['main-nav'].navItems.forEach((item) => {
      if (item.controls === menu.menuId.split('-')[0]) {
        item.element.focus();
        return;
      }
    });
    this.hideSubMenus();
    this.removeActiveClasses();
    this.toggleMenuBackdrop();
    this.sendFilterInfo();
    this.handleCloseBtnClick();
  }

  handleMainNavShow() {
    this.menuButton.setAttribute('aria-label', this.ARIA_LABEL_CLOSE_MENU);
    // eslint-disable-next-line no-undef
    this.mainMenuOffcanvasBs = bootstrap.Offcanvas.getInstance(this.mainNavOffCanvas);
    this.sendFilterInfo();
  }

  handleMainNavHide() {
    this.menuButton.setAttribute('aria-label', this.ARIA_LABEL_OPEN_MENU);
    this.hideSubMenus();
    this.sendFilterInfo();
    this.menuButton.focus();
  }

  handleSearchOffcanvasShow() {
    this.hideSubMenus();
    this.sendFilterInfo();
    this.dropdownBtns.forEach((btn) => {
      btn.classList.remove('active');
    });
    this.backdrop.classList.remove('show');
    // eslint-disable-next-line no-undef
    this.searchOffcanvasBs = bootstrap.Offcanvas.getInstance(this.searchOffcanvas);
    this.searchMenuButton.setAttribute('aria-label', this.ARIA_LABEL_CLOSE_SEARCH);
  }

  handleSearchOffcanvasHide() {
    this.searchMenuButton.setAttribute('aria-label', this.ARIA_LABEL_OPEN_SEARCH);
    this.searchMenuButton.focus();
  }

  handleBackdropClick() {
    this.hideSubMenus();
    this.removeActiveClasses();
    this.toggleMenuBackdrop();
    this.sendFilterInfo();
  }

  handleDropDownButtonClick(btn) {
    this.sendFilterInfo();
    const menu = btn.nextElementSibling;
    if (!menu.classList.contains('show')) {
      if (this.searchOffcanvasBs) this.searchOffcanvasBs.hide();
      this.hideSubMenus();
      this.removeActiveClasses();
      btn.classList.add('active');
      this.backdrop.classList.add('show');
      menu.classList.add('show');
      menu.querySelector('.menu-close-btn').focus();
    } else {
      this.hideSubMenus();
      menu.classList.remove('show');
      this.backdrop.classList.remove('show');
      btn.classList.remove('active');
    }
  }

  async handleBackButtonClick(menuObj) {
    const menu = menuObj.menuElement;
    const parentMenuId = menuObj.menuType === 'level2Menu' ? 'main-nav' : menu.id.split('-')[0];
    const parentMenu = this.megaMenu.menus[parentMenuId];
    const parentBtn = parentMenu.navItems.find((item) => item.controls === menu.id).element;
    const parentUl = Array.from(parentMenu.menuElement.children).find((child) => child.tagName === 'UL');
    menu.classList.remove('show');
    menuObj.resetMenu();
    parentBtn.setAttribute('aria-expanded', 'false');
    parentBtn.classList.remove('active');
    parentBtn.focus();
    if (menuObj.menuType === 'level3Menu') {
      await this.addClassesAfterMenuCloses(menu, parentUl, 200);
    }
    this.sendFilterInfo();
  }

  async handleCloseButtonClick() {
    this.hideSubMenus();
    this.removeActiveClasses();
    this.toggleMenuBackdrop();
    this.sendFilterInfo();
  }

  async handleLevel2MenuButtonClick(menuButton, menu, menuObj) {
    this.sendFilterInfo();
    const parentUl = Array.from(menuObj.menuElement.children).find((child) => child.tagName === 'UL');
    if (!menu.classList.contains('show')) {
      this.hideAllLevel3Menus();
      parentUl.classList.remove('overflow-scroll');
      menu.classList.remove('d-none');
      const navHeight = window.innerWidth > 1200 ? this.mainNavHeightXl : this.mainNavHeight;
      menu.style.top = `-${menuButton.getBoundingClientRect().top - navHeight}px`;
      // This little bit keeps the menu from getting cut off at the bottom of the screen
      const menuUl = Array.from(menu.children).find((child) => child.tagName === 'UL');
      menuUl.style.height = `${window.innerHeight - menuUl.getBoundingClientRect().top}px`;
      menu.classList.add('show');
      this.megaMenu.menus[menu.id].navItems[this.megaMenu.menus[menu.id].firstFocusableElement].element.focus();
      menuButton.setAttribute('aria-expanded', 'true');
      menuObj.navItems.forEach((item) => item.element.classList.remove('active'));
      menuButton.classList.add('active');
    } else {
      menu.classList.remove('show');
      menuButton.classList.remove('active');
      menu.previousElementSibling.setAttribute('aria-expanded', 'false');
      await this.addClassesAfterMenuCloses(menu, parentUl, 200);
    }
  }

  async addClassesAfterMenuCloses(menu, parentUl, delayTime) {
    await this.delay(delayTime);
    menu.classList.add('d-none');
    parentUl.classList.add('overflow-scroll');
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  debounce(func, delay) {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  sendFilterInfo() {
    if (this.hasFilterData(this.globalNavElement)) {
      const filterData = this.getFilterData(this.globalNavElement);
      this.clearFilterData(this.globalNavElement);
      //TODO: this will be sent to datalayer in a future PR
      console.log(filterData);
    }
  }

  hasFilterData(el) {
    return (
      el.hasAttribute('data-filter-menu-id') &&
      el.hasAttribute('data-filter-result-count') &&
      el.hasAttribute('data-filter-text')
    );
  }

  clearFilterData(el) {
    el.removeAttribute('data-filter-menu-id');
    el.removeAttribute('data-filter-result-count');
    el.removeAttribute('data-filter-text');
  }

  getFilterData(el) {
    return {
      menuId: el.getAttribute('data-filter-menu-id'),
      resultCount: el.getAttribute('data-filter-result-count'),
      filterText: el.getAttribute('data-filter-text'),
    };
  }

  hideSubMenus() {
    this.hideAllLevel3Menus();
    this.level2Menus.forEach((menu) => {
      menu.menuElement.classList.remove('show');
      menu.menuElement.previousElementSibling.setAttribute('aria-expanded', 'false');
      menu.navItems.forEach((item) => item.element.classList.remove('active'));
      menu.resetMenu();
    });
  }

  hideAllLevel3Menus() {
    this.level3Menus.forEach((menu) => {
      if (menu.menuElement.classList.contains('show')) {
        menu.menuElement.classList.remove('show');
        menu.menuElement.previousElementSibling.setAttribute('aria-expanded', 'false');
        this.clearMenuFilter(menu.menuElement);
        setTimeout(() => {
          menu.menuElement.classList.add('d-none');
        }, 200);
      }
    });
  }

  removeActiveClasses() {
    this.dropdownBtns.forEach((btn) => btn.classList.remove('active'));
  }

  toggleMenuBackdrop() {
    if (this.backdrop.classList.contains('show')) {
      this.backdrop.classList.remove('show');
    } else {
      this.backdrop.classList.add('show');
    }
  }

  setupMenuFilter(menu) {
    const filterInput = menu.querySelector('input[type="text"]');
    const numResultsMsg = menu.querySelector('.number-of-results');
    const menuItems = menu.querySelectorAll('[role="menuitem"]');
    const filterDiv = menu.querySelector('.menu-filter');
    const menuUl = menu.querySelector('ul');
    const filterClearBtn = filterDiv.querySelector('button');
    const debouncedFilterMenuItems = this.debounce(
      (event) => this.filterMenuItems(event, menu, filterInput, menuItems, numResultsMsg),
      250
    );
    const debouncedScrollHandler = this.debounce(() => this.handleScroll(menuUl, filterDiv), 250);

    filterInput.addEventListener('keyup', debouncedFilterMenuItems);
    menuUl.addEventListener('scroll', debouncedScrollHandler);
    filterClearBtn.addEventListener('click', () => {
      this.clearMenuFilter(menu);
      filterInput.focus();
    });
  }

  filterMenuItems(event, menu, filterInput, menuItems, numResultsMsg) {
    if (event.key === 'Escape') {
      filterInput.value = '';
      this.sendFilterInfo();
    } else if (event.key === 'Backspace' && filterInput.value === '') {
      this.sendFilterInfo();
    }

    const filter = filterInput.value.toLowerCase().trim();
    const filterClearBtn = menu.querySelector('.menu-filter button');
    if (filter.length > 0) {
      filterClearBtn.classList.remove('d-none');
    } else {
      filterClearBtn.classList.add('d-none');
    }
    menuItems.forEach((item) => {
      const text = item.textContent.toLowerCase();
      item.parentElement.style.display = text.includes(filter) ? 'block' : 'none';
    });

    const results = this.updateResultCount(menuItems, numResultsMsg);
    this.globalNavElement.setAttribute('data-filter-menu-id', menu.id);
    this.globalNavElement.setAttribute('data-filter-result-count', results);
    this.globalNavElement.setAttribute('data-filter-text', filter);
  }

  clearMenuFilter(menu) {
    const filterInput = menu.querySelector('input[type="text"]');
    const numResultsMsg = menu.querySelector('.number-of-results');
    const menuItems = menu.querySelectorAll('[role="menuitem"]');
    const filterClearBtn = menu.querySelector('.menu-filter button');
    filterInput.value = '';
    filterClearBtn.classList.add('d-none');
    menuItems.forEach((item) => {
      item.parentElement.style.display = 'block';
    });
    this.updateResultCount(menuItems, numResultsMsg);
    this.sendFilterInfo();
  }

  updateResultCount(menuItems, numResultsMsg) {
    const resultCount = Array.from(menuItems).filter((item) => item.parentElement.style.display !== 'none').length;
    numResultsMsg.textContent = `Showing ${resultCount} result${resultCount !== 1 ? 's' : ''}${
      resultCount === 0 ? ', clear text to see all results.' : ''
    }`;
    return resultCount;
  }

  handleScroll(menuUl, filterDiv) {
    if (menuUl.scrollTop > 0) {
      filterDiv.classList.add('shadow-sm');
    } else {
      filterDiv.classList.remove('shadow-sm');
    }
  }

  searchKeyboardA11y(e) {
    if (e.code !== 'Tab') return;

    const navDropDownChoices = new Set(this.searchLearningLevelDropdownChoices);
    const panelNav = this.searchPanelNav;
    let item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();

    while (navDropDownChoices.has(item)) {
      item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
    }

    item.focus();
    e.stopPropagation();
    e.preventDefault();
  }

  navKeyboardA11y(e) {
    if (e.code !== 'Tab') return;

    if (e.target.classList.contains('menu-close-btn')) {
      this.megaMenu.menus['main-nav'].getCurrentItem().element.focus();
    } else {
      const sibling = e.shiftKey
        ? e.target.parentElement.previousElementSibling
        : e.target.parentElement.nextElementSibling;

      if (sibling) {
        sibling.children[0].focus();
        e.stopPropagation();
        e.preventDefault();
      }
    }
  }

  level2KeyboardA11y(e, panelNav) {
    if (e.code === 'Escape') {
      this.handleLevel2CloseBtnClick(panelNav);
    } else if (e.code === 'Tab') {
      let item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
      while (item.element.offsetParent === null) {
        item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
      }
      if (item) item.element.focus();
    }

    if (e.code === 'Escape' || e.code === 'Tab') {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  level3KeyboardA11y(e, panelNav) {
    if (e.code !== 'Tab') return;

    if (e.target.tagName === 'INPUT' && e.target.value) {
      e.target.nextElementSibling.focus();
    } else {
      let item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
      while (item.element.parentElement.style.display === 'none') {
        item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
      }
      if (item) item.element.focus();
    }

    e.stopPropagation();
    e.preventDefault();
  }

  setupSearchA11y() {
    const searchPanelElements = Array.from(
      this.searchOffcanvas.querySelectorAll('a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])')
    );
    this.searchPanelNav = new SearchPanel(null, searchPanelElements);
  }
}
